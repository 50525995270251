import React from 'react';
import './HomePage.css'; 

import galleryOfStrangeIcon from './img/galleryOfStrangeIcon.png';
import makeYourOwnMannersIcon from './img/makeYourOwnMannersIcon.png';
import myMemoryMapIcon from './img/myMemoryMapIcon.png';
import partyOfGhostsIcon from './img/partyOfGhostsIcon.png';
import thatTreeJustTalkedIcon from './img/thatTreeJustTalkedIcon.png';
import theDarknessGameIcon from './img/theDarknessGameIcon.png';
import theMagicStoryMakerIcon from './img/theMagicStoryMakerIcon.png';
import theOppositeMeIcon from './img/theOppositeMeIcon.png';
import thisRoomsAliveIcon from './img/thisRoomAliveIcon.png';
import ticketToATinyShowIcon from './img/ticketToATinyShowIcon.png';

const adventures = [
  { id: 1, url: 'https://gallery-of-strange.child-nation-adventures.com/', image: galleryOfStrangeIcon },
  { id: 2, url: 'https://make-your-own-manners.child-nation-adventures.com/', image: makeYourOwnMannersIcon },
  { id: 3, url: 'https://my-memory-map.child-nation-adventures.com/', image: myMemoryMapIcon },
  { id: 4, url: 'https://party-of-ghosts.child-nation-adventures.com/', image: partyOfGhostsIcon },
  { id: 5, url: 'https://that-tree-just-talked.child-nation-adventures.com/', image: thatTreeJustTalkedIcon },
  { id: 6, url: 'https://the-darkness-game.child-nation-adventures.com/', image: theDarknessGameIcon },
  { id: 7, url: 'https://the-magic-story-maker.child-nation-adventures.com/', image: theMagicStoryMakerIcon },
  { id: 8, url: 'https://the-opposite-me.child-nation-adventures.com/', image: theOppositeMeIcon },
  { id: 9, url: 'https://this-rooms-alive.child-nation-adventures.com/', image: thisRoomsAliveIcon },
  { id: 10, url: 'https://ticket-to-a-tiny-show.child-nation-adventures.com/', image: ticketToATinyShowIcon }
];

const HomePage = () => (
  <div>
    <div className="header">
      <h1 className="main-title">Yes! You've arrived.</h1>
      <h2 className="sub-title">NOW CHOOSE AN ADVENTURE</h2>
    </div>
    <div className="adventure-grid">
      {adventures.map(adventure => (
        <a key={adventure.id} href={adventure.url} rel="noopener noreferrer">
          <div className={`adventure-item adventure-item-${adventure.id}`}>
            <img src={adventure.image} alt={`Adventure ${adventure.id}`} />
          </div>
        </a>
      ))}
    </div>
  </div>
);

export default HomePage;
